import React from 'react';
import {Box, Tooltip, Typography} from "@mui/material";
import Empty from "../../images/EmptyPage/Empty.svg"
import {storage} from "../../utils/storage";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import {useNavigate} from "react-router-dom";

export const MenuPage = () => {
    const {patientInfo, setCollapsed} = React.useContext(QuestionaryContext);
    const navigate = useNavigate();
    React.useEffect(()=>{
        setCollapsed(false);
    },[])
    return (
        <Box component="section"
             display="flex"
             alignItems="center"
             flexDirection={'column'}
             height={'100%'}
             className={'relative'}>
            {/*disable findHelp*/}
            {/*{(patientInfo && patientInfo?.isFindHelpEnable) ?*/}
            {/*    <div className={'findHelpUrl'} onClick={() => {*/}
            {/*        navigate('/findHelp')*/}
            {/*    }}>Launch FindHelp</div>*/}
            {/*    :*/}
            {/*    null*/}
            {/*    // <Tooltip title={'FindHelp is not enabled for this patient'} placement={'top'}>*/}
            {/*    //     <div className={'findHelpUrl'}>Launch FindHelp</div></Tooltip>*/}
            {/*}*/}

            <Box height={'100%'}
                 display="flex"
                 alignItems="center"
                 flexDirection={'column'}>
                <Box height={'80%'}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     flexDirection={'column'}
                     gap={'24px'}>
                    <div className='emptyImageContainer'>
                        <div
                            id={'emptyImage'} className={'emptyImage'}
                            style={{backgroundImage: 'url(' + Empty + ')'}}/>
                    </div>
                    <div>
                        {storage.getItem('permission', undefined) === 'All' ?
                            <Typography variant="subtitle1">Get started by conducting a new screening.</Typography>
                            : <Typography variant="subtitle1" display={'flex'}>Click on the<MoreHorizIcon
                                style={{color: '#0C77D8', height: 24, width: 24, padding: '0 8px'}}/>to view screening
                                details.</Typography>
                        }
                    </div>
                </Box>
            </Box>
        </Box>
    )
}