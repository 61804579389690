import React from 'react';
import {QuestionaryContext} from "../../context/QuestionaryContext";

import axiosHttp from "../../utils/axios";
import {Box, CircularProgress, Divider, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomConfirmDialog from "../questionary/components/QuestionHeader/CustomConfirmDialog";
import CloseIcon from "@mui/icons-material/Close";
import {FindHelpIframe} from "./findHelpIframe";

export const FindHelpHome = () => {
    const {mpid, patientInfo, collapsed} = React.useContext(QuestionaryContext);
    const [url, setUrl] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [onClickClose, setOnClickClose] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/CommunityResource/Findhelp', {
                    "mpid": mpid,
                    "action": "GetSeekerProfileTokenRedirectUrl"
                });
                setUrl(response?.data?.data);
                setLoading(false);
            } catch (e) {
                console.log(e)
            }

        }
        if (mpid) {
            getData();
        }
    }, [mpid])

    if (patientInfo && patientInfo?.isFindHelpEnable === false) {
        navigate('/home')
    }
    return loading ? <Box component="section"
                          bgcolor={'#FFFFFF'}
                          display="flex"
                          alignItems="center"
                          flexDirection={'column'}
                          justifyContent={'center'}
                          height={'100%'}>
            <CircularProgress/>
        </Box> :
        <Box display="flex"
             flexDirection={'column'}
             position={'relative'}
             bgcolor={'#FFFFFF'}
             justifyContent={'center'}
             height={'100%'}
             sx={{borderRadius: '8px', maxHeight: '100%'}}>
            <CustomConfirmDialog
                title={'Close Find Help'}
                content={'Are you sure you want to close without saving?\nAny unsaved changes will be lost.'}
                open={onClickClose}
                onClose={() => setOnClickClose(false)}
                onConfirm={() => {
                    navigate('/menu')
                }}
            />
            <Box>
                <Box height={40} className={collapsed?"collapseBox":"notCollapseBox"}>
                    <Typography variant="h3">Community Resources - Findhelp</Typography>
                </Box>
                <Divider/>
            </Box>
            <CloseIcon className='closeButton' onClick={() => {
                setOnClickClose(!onClickClose)
            }}/>
            <FindHelpIframe url={url}/></Box>


}