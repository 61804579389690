import React from 'react';
import {Box, Divider} from "@mui/material";
import {PatientInfoDetailView} from "./PatientInfoDetailView";
import {SocialRiskSummaryView} from "./SocialRiskSummaryView";
import {ScreeningHistoryView} from "./ScreeningHistoryView";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import {CollpaseIcon} from "../../images/Icons/CollpaseIcon";

export const UserDetailView = () => {
    const {mpid, collapsed, setCollapsed} = React.useContext(QuestionaryContext);
    return mpid ? (
        <Box sx={{width: 400, bgcolor: '#FFFFFF', height: '100%', position: 'relative', borderRadius: '8px'}}
             padding='0'>
            {/*patientinfo*/}
            <div className={"sidebarCollapseIcon"} onClick={() => setCollapsed(!collapsed)}><CollpaseIcon/></div>

            <PatientInfoDetailView/>
            <Divider/>
            {/*risk*/}
            <Box sx={{overflowY: 'auto', maxHeight: 'calc( 100% - 135px)'}}>
                <SocialRiskSummaryView/>
                {/*History*/}
                <ScreeningHistoryView/>
            </Box>
        </Box>) : null
}