import React from 'react';
import {
    Box,
    Typography,
    Button,
    ListItem,
    List,
    Divider,
    IconButton,
    Menu, MenuItem, CircularProgress
} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axiosHttp from "../../utils/axios";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useLocation, useNavigate} from "react-router-dom";
import {storage} from "../../utils/storage";
import CustomConfirmDialog from "../../scenes/questionary/components/QuestionHeader/CustomConfirmDialog";
import {ConfigIcon} from "../../images/Icons/ConfigIcon";

import moment from 'moment';
import 'moment-timezone';

export const ScreeningHistoryView = () => {
    const {
        mpid,
        setQuestionaryId,
        setQuestionaryPeriodId,
        lastUpdated,
        setHistories,
        setUnfinished
    } = React.useContext(QuestionaryContext);
    const [data, setData] = React.useState({} as any)
    const [rows, setRows] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(true)
    const [selectedHistory, setSelectedHistory] = React.useState<any>('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElHistory, setAnchorElHistory] = React.useState<null | HTMLElement>(null);
    const [nav, setNav] = React.useState<any>(undefined);
    const [onClickNav, setOnClickNav] = React.useState(false);
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const confirmPopUp = pathnames.includes('getOne')
    const open = Boolean(anchorEl);
    const openHistory = Boolean(anchorElHistory)
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickHistory = (event: React.MouseEvent<HTMLButtonElement>, responseSessionId: string) => {
        setAnchorElHistory(event.currentTarget);
        setSelectedHistory(responseSessionId)
    };
    const handleNav = () => {
        if (nav?.path === '/unfinished/getOne') {
            setQuestionaryId(nav?.state?.questionaryId);
            setQuestionaryPeriodId(nav?.state?.questionaryPeriodId);
            navigate(nav?.path);
        } else {
            navigate('/unfinished/getOne');
            navigate(nav?.path);
        }
        setOnClickNav(false);
        setNav(undefined);
    }
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElHistory(null);
        setSelectedHistory('')
    };
    const handleOnClick = (questionary: any) => {
        handleClose();
        if (confirmPopUp) {
            setNav({
                path: '/unfinished/getOne',
                state: {
                    questionaryId: questionary.QuestionaryId,
                    questionaryPeriodId: questionary.QuestionaryPeriodId
                }
            })
            setOnClickNav(true);
        } else {
            setQuestionaryId(questionary.QuestionaryId);
            setQuestionaryPeriodId(questionary.QuestionaryPeriodId);
            navigate('/unfinished/getOne');
        }
    }

    const handleOnClickHistory = () => {
        if (selectedHistory) {
            setAnchorElHistory(null);
            if (confirmPopUp) {
                setNav({path: '/finished/' + selectedHistory})
                setOnClickNav(true);
            } else {
                navigate('/finished/' + selectedHistory);
            }
        }
    }

    const handleOnClickConfig = ()=>{
        handleClose();
        if (confirmPopUp) {
            setNav({path: '/config',})
            setOnClickNav(true);
        } else {
            navigate('/config');
        }
    }

    React.useEffect(() => {
        setLoading(true)
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant',
                    {
                        "mpid": mpid,
                        "action": "FinishedSessions"
                    })
                setData(response?.data?.data);
                setHistories(response?.data?.data);
                setLoading(false);
                return response;

            } catch (error) {
                //handle error here...
                console.log(error)
            }

        }
        getData()
        const getRows = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant',
                    {
                        "mpid": mpid,
                        "action": "Unfinished"
                    })
                setRows(response?.data?.data);
                setUnfinished(response?.data?.data);
                return response;

            } catch (error) {
                //handle error here...
                console.log(error)
            }

        }
        if (mpid) getRows()
    }, [mpid, lastUpdated])
    return (
        loading ?
            <Box padding={'16px 16px 24px 16px'} display={'flex'} justifyContent={'center'}><CircularProgress/></Box> :
            <Box padding={'16px 16px 24px 16px'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'} sx={{minHeight: 56}}>
                    <CustomConfirmDialog
                        title={'Close Screening'}
                        content={'Are you sure you want to close without saving?\nAny unsaved changes will be lost.'}
                        open={onClickNav}
                        onClose={() => setOnClickNav(false)}
                        onConfirm={() => {
                            handleNav()
                        }}
                    />
                    <Typography variant="h3" sx={{whiteSpace: "nowrap"}}>Screening History<IconButton onClick={()=>{handleOnClickConfig();}}><ConfigIcon/></IconButton></Typography>
                    {storage.getItem('permission', undefined) === 'All' ?
                        <Button
                            disabled={!rows || rows?.length < 1}
                            startIcon={<AddCircleIcon color={'primary'} sx={{"&&": {fontSize: 24}}}/>}
                            color="inherit"
                            aria-controls={open ? 'new-screening-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{textTransform: 'none', whiteSpace: "nowrap"}}><Typography variant={'subtitle1'}>New
                            Screening</Typography></Button>
                        : null}
                </Box>
                <Menu
                    id="new-screening-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        sx: {ml: 1, mr: 1}
                    }}
                >
                    {rows.map((row: any) => {
                        return (
                            <MenuItem
                                key={row.QuestionaryId}
                                onClick={() => {
                                    handleOnClick(row)
                                }}
                                sx={{borderRadius: 2}}>
                                {row.Questionary.QuestionaryName}
                            </MenuItem>)
                    })}
                </Menu>
                <Box className={'greyBackground'} sx={{height: 40, width: '100%'}} display={'flex'}
                     alignItems={'center'}>
                    <Typography variant="subtitle2">Screening Name</Typography>
                </Box>
                <Menu
                    id="screening-history-menu"
                    anchorEl={anchorElHistory}
                    open={openHistory}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        sx: {ml: 1, mr: 1}
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleOnClickHistory()
                        }}
                        sx={{borderRadius: 2}}>
                        View
                    </MenuItem>
                </Menu>
                <List>
                    {data?.length > 0 ?
                        data?.sort(function (a: any, b: any) {
                            if (a.UpdateDateTime > b.UpdateDateTime) return -1;
                            if (a.UpdateDateTime < b.UpdateDateTime) return 1;
                        }).map((session: any, index: number) => {
                            return (
                                <div key={session?.Questionary.QuestionaryName + `_${index}`}>
                                    <ListItem
                                        key={session?.Questionary.QuestionaryName + `_${index}`}
                                        alignItems={'center'}
                                        sx={{justifyContent: 'space-between', padding: '0'}}>
                                        <Typography
                                            variant="body1">{session?.Questionary.QuestionaryName}</Typography>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Typography
                                                variant="h6">{moment(session.UpdateDateTime).format('MMM DD,YYYY')}</Typography>
                                            <IconButton
                                                color="primary"
                                                aria-controls={openHistory ? 'screening-history-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openHistory ? 'true' : undefined}
                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClickHistory(event, session.ResponseSessionId)}
                                            >
                                                <MoreHorizIcon sx={{height: '24px', width: '24px'}}/>
                                            </IconButton>
                                        </Box>
                                    </ListItem>
                                    <Divider/>
                                </div>)
                        })
                        :
                        <>
                            <ListItem alignItems={'center'}
                                      sx={{justifyContent: 'space-between', padding: '0'}}><Typography
                                variant="body1">No Screening History.</Typography></ListItem>
                            <Divider/>
                        </>
                    }
                </List>
            </Box>
    )
}
