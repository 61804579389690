export const config = {
    baseURL: 'https://fqw3hzwjtj.execute-api.us-east-1.amazonaws.com/prod',
    csEmail: 'customersupport@mdland.com',
    versionNumber: 'prod_1.0.2',
    addressHardCodeSets: [{
        id: 1,
        questionName: 'What address do you live at?'
    }],
    consentHardCodeSets: [{
        SectionName: "Consent",
        enable: "Member consents.",
        disable: "Member does not consent."
    }],
    rendr: 9000,
    defaultClinicId: 1007,
    zipRegex: '^(?!00000)\\d{5}(?:[-\\s](?!0000)\\d{4})?$',
    states: [{ value: "AK", text: "Alaska" },
        { value: "AL", text: "Alabama" },
        { value: "AR", text: "Arkansas" },
        { value: "AS", text: "American Samoa" },
        { value: "AZ", text: "Arizona" },
        { value: "CA", text: "California" },
        { value: "CO", text: "Colorado" },
        { value: "CT", text: "Connecticut" },
        { value: "DC", text: "District of Columbia" },
        { value: "DE", text: "Delaware" },
        { value: "FL", text: "Florida" },
        { value: "GA", text: "Georgia" },
        { value: "GU", text: "Guam" },
        { value: "HI", text: "Hawaii" },
        { value: "IA", text: "Iowa" },
        { value: "ID", text: "Idaho" },
        { value: "IL", text: "Illinois" },
        { value: "IN", text: "Indiana" },
        { value: "KS", text: "Kansas" },
        { value: "KY", text: "Kentucky" },
        { value: "LA", text: "Louisiana" },
        { value: "MA", text: "Massachusetts" },
        { value: "MD", text: "Maryland" },
        { value: "ME", text: "Maine" },
        { value: "MI", text: "Michigan" },
        { value: "MN", text: "Minnesota" },
        { value: "MO", text: "Missouri" },
        { value: "MS", text: "Mississippi" },
        { value: "MT", text: "Montana" },
        { value: "NC", text: "North Carolina" },
        { value: "ND", text: "North Dakota" },
        { value: "NE", text: "Nebraska" },
        { value: "NH", text: "New Hampshire" },
        { value: "NJ", text: "New Jersey" },
        { value: "NM", text: "New Mexico" },
        { value: "NV", text: "Nevada" },
        { value: "NY", text: "New York" },
        { value: "OH", text: "Ohio" },
        { value: "OK", text: "Oklahoma" },
        { value: "OR", text: "Oregon" },
        { value: "PA", text: "Pennsylvania" },
        { value: "PR", text: "Puerto Rico" },
        { value: "RI", text: "Rhode Island" },
        { value: "SC", text: "South Carolina" },
        { value: "SD", text: "South Dakota" },
        { value: "TN", text: "Tennessee" },
        { value: "TX", text: "Texas" },
        { value: "UT", text: "Utah" },
        { value: "VA", text: "Virginia" },
        { value: "VI", text: "Virgin Islands" },
        { value: "VT", text: "Vermont" },
        { value: "WA", text: "Washington" },
        { value: "WI", text: "Wisconsin" },
        { value: "WV", text: "West Virginia" },
        { value: "WY", text: "Wyoming" }]
}  as const;