import React from 'react';
// import {Header} from "./header";
// import {Footer} from "./footer";
// import {Breadcrumb} from "./breadcrumb";
import {CustomSnackBar} from "./snackbar";
import {UserDetailView} from "./UserDetail/UserDetailView";
import {useLocation, useNavigate} from "react-router-dom";
import {QuestionaryContext} from "../context/QuestionaryContext";
import {cleanRoute} from "./breadcrumb";
import {NewHeader} from "./newHeader";
import {Grid, Box} from "@mui/material";
import {storage} from "../utils/storage";
import {CollpaseIcon} from "../images/Icons/CollpaseIcon";

export const MainLayout = ({children}: any) => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const {mpid, cleanSession, collapsed, setCollapsed} = React.useContext(QuestionaryContext);
    const navigate = useNavigate();
    const source = storage.getItem("source", undefined);
    React.useEffect(() => {
        if (cleanRoute.includes(location.pathname)) {
            cleanSession();
        }
        if (!mpid && pathnames.length > 0 && pathnames[0] !== 'sso') {
            navigate('/home');
        }
    }, [location.pathname])
    return (
        <div id={'mainLayout'}>
            {mpid ? (source === 'iClinic' ? null : <NewHeader/>) : null}
            {/*<Breadcrumb/>*/}

            <Box sx={{display: 'flex', height: source === 'iClinic' ? '100vh' : 'calc( 100vh - 72px )', width: '100%'}} id={'mainBox'}>
                <CustomSnackBar/>
                <Box id={'mainBoxLeft'} className={collapsed ? 'hidden' : undefined}>
                    <UserDetailView/>
                </Box>
                <div className={collapsed ? "whole_iclinic section-to-print" : `main_iclinic section-to-print`}>
                    {collapsed ? <div className={"mainContentCollapseIcon"} style={{top: source === 'iClinic'?"30px":"102px"}} onClick={() => setCollapsed(!collapsed)}>
                        <CollpaseIcon/>
                    </div> : null}
                    {children}</div>
            </Box>
            {/*<Footer/>*/}
        </div>
    )
}