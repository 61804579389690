import * as React from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import {useQuestionaryContext} from "../context/QuestionaryContext";
import CustomConfirmDialog from "../scenes/questionary/components/QuestionHeader/CustomConfirmDialog";
import {config} from "../config/config";

interface Props{
    question: any,
    setAnswer: any,
    setAnswerText?: any,
    currentSection: number
    currentQuestion: number
    answers: any;
    checked?: boolean;
    hardCode?: any;
    resetForNoConsent: any;
}

export const SelectWithConfirm = (props: Props) => {
    const {question} = props;
    const {languageId} = useQuestionaryContext();
    const [selected, setSelected] = React.useState('');
    const [onClickConsent, setOnClickConsent] = React.useState(false);
    function getQuestionTranslation(question: any, attribute: string, defaultValue?: string) {
        try {
            let result = question?.QuestionTranslation?.find((tran: any) => tran.LanguageId === languageId)?.[`${attribute}`]
            return result || defaultValue
        } catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    function getAnswerTranslation(answer: any, attribute: string, defaultValue?: string) {
        try {
            let result = answer?.AnswerTranslation?.find((tran: any) => tran.LanguageId === languageId)?.[`${attribute}`]
            return result || defaultValue
        } catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    const confirmChange = ()=>{
        let answerName = question.Answer.find((a: any)=>a.AnswerCode === selected)?.AnswerName
        if(config.consentHardCodeSets.find((set: any)=>set.disable===answerName)) {
            props.resetForNoConsent();
        }
        else {
            props.setAnswer(props.currentSection, props.currentQuestion, selected)
        }
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = (event.target as HTMLInputElement).value
        if (v) {
            setSelected(v);
            setOnClickConsent(true)
        }
    }

    return(<FormControl required>
            <CustomConfirmDialog
                title={'Change consent'}
                content={'Are you sure you want to change the consent'}
                open={onClickConsent}
                onClose={() => {setOnClickConsent(false)}}
                onConfirm={() => {
                    confirmChange()
                    setOnClickConsent(false)
                }}
            />
            <Typography id="checkBox-label"
                        variant='h5'  mt={1.5} mb={1}> {getQuestionTranslation(question, 'QuestionContent', question.QuestionName)}<span
                className='red'>*</span></Typography>
            {!props.answers.Answer?.option && props.checked ?
                <Typography color='error' className={'custom-error'}>Please select an option.</Typography> : null}
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={props.answers.Answer?.option ?? ''}
                name="radio-buttons-group"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRadioChange(event)}
            >
                {question.Answer.map((answer: any, index: number) => {
                    return (
                        <div key={answer?.AnswerId + index + 'single'} className={'flex'}
                             style={{minHeight: '24px',paddingBottom: index!==question.Answer.length-1?'8px':0}}>
                            <FormControlLabel
                                key={answer?.AnswerId}
                                value={answer?.AnswerCode}
                                control={<Radio
                                    style={{
                                        transform: "scale(0.87)",
                                    }}
                                    size={'small'} sx={{padding: '0px 9px 0px 9px'}}/>}
                                label={<div className={'answerText'}>{getAnswerTranslation(answer, 'AnswerTranslationContent', answer?.AnswerName)}</div>} />
                        </div>
                    )
                })}
            </RadioGroup>
        </FormControl>
    )
};