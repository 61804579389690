import React from 'react';
import {
    Box,
    CircularProgress,
    FormControl,
    MenuItem,
    OutlinedInput,
    Select, styled,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {ConfigPageHeader} from "./components/ConfigPageHeader";
import CloseIcon from "@mui/icons-material/Close";
import CustomConfirmDialog from "../questionary/components/QuestionHeader/CustomConfirmDialog";
import {useNavigate} from "react-router-dom";
import {useQuestionaryContext} from "../../context/QuestionaryContext";
import axiosHttp from "../../utils/axios";
import {config} from "../../config/config";
import {EmptyConfigScreen} from "./components/EmptyConfigScreen";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    padding?: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{p: props.padding ?? '16px 24px'}}>{children}</Box>}
        </div>
    );
}

interface StyledTabProps {
    label: string;
}

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: 700,
        color: '#656565',
        fontSize: '14px',
        lineHeight: '24px',
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#0C77D8',
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }),
);

export const ConfigHomePage = () => {
    const [onClickClose, setOnClickClose] = React.useState(false);
    const navigate = useNavigate();
    const {unfinished, patientInfo, mpid} = useQuestionaryContext();
    const [questionary, setQuestionary] = React.useState<any>(null);
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState({} as any)
    const [loading, setLoading] = React.useState(true)
    const [changedTab, setChangedTab] = React.useState(false);
    const [changedTab2, setChangedTab2] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant', {
                    "mpid": mpid,
                    "questionaryId": questionary?.QuestionaryId,
                    "action": "GetOneWithICD",
                    "clinicId": patientInfo.clinicId ?? config.defaultClinicId,
                    "divisionId": patientInfo.divisionId ?? undefined
                })
                setData(response?.data?.data);
                let temp = false;
                if (patientInfo.divisionId) {
                    if (response?.data?.data?.DivisionICDCustomQuestionary && response?.data?.data?.DivisionICDCustomQuestionary?.length > 0) {
                        temp = true;
                    }
                } else if (response?.data?.data?.ClinicICDCustomQuestionary && response?.data?.data?.ClinicICDCustomQuestionary?.length > 0) {
                    temp = true;
                }
                let temp2 = false;
                response?.data?.data?.Section.map((section: any) => {
                    if (patientInfo.divisionId) {
                        if (section?.DivisionICDCustomSection && section?.DivisionICDCustomSection?.length > 0) {
                            temp = true;
                        }
                        if (section.Question?.length > 0) {
                            section.Question.map((question: any) => {
                                question.Answer.map((answer: any, index: number) => {
                                    if (answer?.DivisionICDCustomAnswer && answer?.DivisionICDCustomAnswer?.length > 0) {
                                        temp2 = true;
                                    }
                                })
                            })
                        }
                    } else {
                        if (section?.ClinicICDCustomSection && section?.ClinicICDCustomSection?.length > 0) {
                            temp = true;
                        }
                        if (section.Question?.length > 0) {
                            section.Question.map((question: any) => {
                                question.Answer.map((answer: any, index: number) => {
                                    if (answer?.ClinicICDCustomAnswer && answer?.ClinicICDCustomAnswer?.length > 0) {
                                        temp2 = true;
                                    }
                                })
                            })
                        }
                    }
                });
                setChangedTab(temp);
                setChangedTab2(temp2);


            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        if (questionary) {
            getData();
        }
    }, [questionary?.QuestionaryId]);
    React.useEffect(() => {
        if (unfinished) {
            setQuestionary(unfinished[0])
        }
    }, [unfinished]);

    React.useEffect(() => {
        setLoading(false)
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getICDSpan = (clinicICDCustomQuestionary: any) => {
        if (clinicICDCustomQuestionary && clinicICDCustomQuestionary?.length > 0) {
            return <span
                className={'icdSpan'}>{clinicICDCustomQuestionary.map((icd: any) => icd?.ICD.ICDCode).join(', ')}</span>
        }
    }

    return (loading ?
        <Box component="section"
             bgcolor={'#FFFFFF'}
             display="flex"
             alignItems="center"
             flexDirection={'column'}
             justifyContent={'center'}
             height={'100%'}>
            <CircularProgress/>
        </Box>
        : <Box
            display="flex"
            flexDirection={'column'}
            position={'relative'}
            bgcolor={'#FFFFFF'}
            justifyContent={'center'}
            sx={{borderRadius: '8px', maxHeight: '100%'}}>
            <CustomConfirmDialog
                title={'Close Config'}
                content={'Are you sure you want to close without saving?\nAny unsaved changes will be lost.'}
                open={onClickClose}
                onClose={() => setOnClickClose(false)}
                onConfirm={() => {
                    navigate('/menu')
                }}
            />
            <ConfigPageHeader/>
            <Box sx={{display: 'flex', flexDirection: 'column', overflowY: 'auto'}} padding='16px 15.5px 0 15.5px'>
                <CloseIcon className='closeButton' onClick={() => {
                    setOnClickClose(!onClickClose)
                }}/>
                <Box display={'flex'} paddingTop={'8px'} paddingBottom={'8px'}>
                    <FormControl fullWidth size={'small'}>
                        <Typography variant='h5'>Screening</Typography>
                        <Select
                            id="screening-select"
                            value={questionary?.QuestionaryId}
                            input={<OutlinedInput sx={{fontSize: '14px', fontWeight: 400}}/>}
                        >
                            {unfinished.map((q: any) => {
                                return <MenuItem
                                    key={q.QuestionaryId}
                                    value={q.QuestionaryId}
                                    onClick={() => {
                                        setQuestionary(q)
                                    }}
                                >{q?.Questionary.QuestionaryName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <AntTab label="Screening & Section"/>
                        <AntTab label="Question & Response"/>
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} padding={changedTab ? "16px 24px" : "0"}>
                    {changedTab ? <>
                        <Box pb={'16px'}>
                            <Typography variant={'h5'} pb={'8px'}>Screening</Typography>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#434343'
                            }}>{questionary?.Questionary.QuestionaryName} {getICDSpan(patientInfo?.divisionId?data?.DivisionICDCustomQuestionary:data?.ClinicICDCustomQuestionary)}
                            </Typography>

                        </Box>
                        <Box>
                            <Typography variant={'h5'} pb={'8px'}>Sections</Typography>
                            <Box>{data?.Section?.length > 0 ? data.Section.map((section: any) => {
                                if (section.Question?.length > 0) {
                                    return <Box pb={'16px'}>
                                        <Typography sx={{
                                            fontWeight: 500,
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            color: '#434343'
                                        }}>{section.SectionName}
                                            {getICDSpan(patientInfo?.divisionId?section?.DivisionICDCustomSection:section?.ClinicICDCustomSection)}</Typography>
                                    </Box>
                                }
                                return null;
                            }) : null}</Box>
                        </Box>
                    </> : <EmptyConfigScreen/>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} padding={changedTab2 ? "16px 24px" : "0"}>
                    {changedTab2 ? data.Section.map((section: any) => {
                        if (section.Question?.length > 0) {
                            return <Box pb={'16px'}>
                                <Typography variant={'h4'} pb={'8px'}>{section.SectionName}</Typography>
                                {section.Question.map((question: any) => {
                                    return (<Box>
                                        <Typography variant={'h5'} pb={'8px'}>{question.QuestionName}</Typography>
                                        {question.Answer.map((answer: any, index: number) => {
                                            return (<Typography sx={{
                                                fontWeight: 500,
                                                fontSize: '16px',
                                                lineHeight: '24px',
                                                color: '#434343'
                                            }}
                                                                pb={'8px'}>{index + 1}. {answer.AnswerName}{getICDSpan(patientInfo?.divisionId?answer?.DivisionICDCustomAnswer:answer?.ClinicICDCustomAnswer)}</Typography>)
                                        })}</Box>)
                                })}
                            </Box>
                        }
                    }) : <EmptyConfigScreen/>}
                </CustomTabPanel>
            </Box>
        </Box>);
}